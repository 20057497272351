
import Vue from "vue";
import ClientData from "@/modules/orders/components/ClientData.vue";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import FileUploader from "@/components/form-components/FileUploader.vue";
// import CommentsMixin from "@/modules/orders/mixins/comments";
import Comments from "@/modules/orders/components/Comments.vue";

export default Vue.extend({
  name: "Create",

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    items: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  components: {
    Comments,
    FileUploader,
    ClientData
  },

  directives: { mask },

  // mixins: [CommentsMixin],

  data: () => ({
    rules,
    selectedClient: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      experts: [{ percent: 100 }] as Array<any>,
      order: {
        source: "operator",
        client: { type: "" } as any,
        power_of_attorney: "beneficiary" as string
      } as any,
      details: { guaranty_required: true } as any
    } as any,
    selects: {
      expertiseTypes: [] as Array<SelectComponentInterface>,
      experts: [] as Array<SelectComponentInterface>
    } as any,
    errorMessages: {} as { [value: string]: Array<string> },
    lang: store.getters["localization/getCurrent"],
    client: { type: "juridical" as string } as any,
    isLoading: false as boolean,
    loading: false as boolean,
    documents: {
      confirm_represent_power: {
        file: {} as any
      } as any
    } as any,
    selectedTab: 0 as number,
    powerOfAttorney: null as any,
    maxDocumentCounter: 20 as number,
    comments: {} as any,
    forceKey: 0 as number,
    oldComments: null as any
  }),

  watch: {
    language: {
      immediate: true,
      handler() {
        this.model.order.language = this.language;
      }
    },
    items: {
      immediate: true,
      handler() {
        this.comments = this.items;
      }
    },
    "model.experts": {
      deep: true,
      immediate: true,
      handler() {
        const mainExpert = this.model.experts.find(
          (item: any) => item.user_id === this.model.order.handler_id
        );

        if (mainExpert) {
          mainExpert.percent = 100 - this.expertsPercent;
        }
      }
    }
  },

  computed: {
    expertsPercent() {
      return this.model.experts.reduce(
        (
          total: number,
          { percent, user_id }: { percent: number; user_id: number }
        ) => {
          if (Number(percent) && this.model.order.handler_id !== user_id) {
            return total + Number(percent);
          }
          return total;
        },
        0
      );
    },
    maxDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    isClassic() {
      return this.model.power_of_attorney === "classic";
    },
    isValidPercent() {
      return this.totalPercent !== 100;
    },
    totalPercent() {
      return this.model.experts.reduce(
        (total: number, { percent }: { percent: number }) => {
          if (Number(percent)) {
            return total + Number(percent);
          }
          return total;
        },
        0
      );
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    checkMainExpert(expertId: number) {
      if (!this.model.order.handler_id || this.model.experts.length === 1) {
        this.model.order.handler_id = expertId;
      }
      this.$forceUpdate();
    },
    async reload(): Promise<void> {
      await this.$forceUpdate();
    },
    async loadData() {
      this.loading = true;
      try {
        this.model = await this.$API
          .orders()
          .show(Number(this.$route.params.id));

        this.$nextTick(() => {
          this.fullLoaded = true;
        });
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      await this.$emit("submit");
    },
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    updateComments(event: any) {
      this.comments[event.key] = event;
      this.$emit("input", this.comments);
      this.changeKey();
    },
    setComment(val: any) {
      this.comments[val.key] = val;
      this.$forceUpdate();
    }
  }
});
